.allies-select {
   width: 75%;

   margin: 0 auto;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1rem;
}

.allies-select > p {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   color: var(--light-grey);
}

.allies-custom-select-container {
   width: 100%;

   font-size: 1.8rem;
   font-weight: 300;
   line-height: 100%;
   text-align: center;
}

.allies-custom-select {
   background-color: #FFFFFF;

   border: var(--input-border);
   
   padding: 0.5rem 1rem;

   border-radius: 2rem;
}

.allies {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.ally {
   width: 100%;
   
   padding: 1rem 2rem;

   background-color: #FFFFFF;

   border: var(--input-border);

   border-radius: 1rem;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 2rem;
}

.ally-title {
   font-size: 1.8rem;
   font-weight: 500;
   text-align: center;

   color: var(--dark-main-color);
}

.ally-image {
   width: 50%;

   display: flex;
}

.ally-image > img {
   width: 100%;

   aspect-ratio: 3 / 2;
   object-fit: contain;

   border-radius: 0.5rem;
}

.ally-info {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.ally-info-col {
   display: flex;
   flex-direction: column;
}

.ally-info-col > span {
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);

   margin-bottom: 0.5rem;
}

.ally-info-col > p {
   font-weight: 300;
   line-height: 100%;

   color: var(--light-grey);
}

.ally-button {
   color: #FFFFFF;
   background-color: var(--dark-main-color);

   padding: 1rem 3rem;

   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-transform: uppercase;
   letter-spacing: 0.1rem;

   border-radius: 2rem;

   cursor: pointer;

   transition: background-color 0.2s ease-in-out;
}

@media only screen and (min-width: 768px) {
   .ally-button {
      cursor: pointer;
   
      transition: background-color 0.2s ease-in-out;
   }

   .ally-button:hover {
      background-color: var(--main-color);
   }
}