.banners-container {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

@media only screen and (min-width: 768px) {
   .banners-container {
      flex-direction: row;
      column-gap: 1rem;
   }
}

.main-banners-container {
   width: 100%;

   border-radius: 1rem;

   overflow: hidden;

   box-shadow: var(--element-shadow);

   background-color: var(--element-shadow-color);

   aspect-ratio: 5 / 2;
   object-fit: contain;
}

@media only screen and (min-width: 768px) {
   .main-banners-container {
      width: unset;

      flex-grow: 1;

      box-shadow: none;
   
      background-color: transparent;
   }
}

.main-banners-element {
   display: flex;

   width: 100%;
}

.main-banners-element > img {
   width: 100%;

   border-radius: 1rem;

   aspect-ratio: 5 / 2;
   object-fit: fill;
}

.dual-banners-container {
   width: auto;

   z-index: 1;

   margin: 0 -1rem;
}

@media only screen and (min-width: 768px) {
   .dual-banners-container {
      width: calc(33% - 0.8rem);

      margin: -0.5rem 0;

      flex-shrink: 0;

      overflow: hidden;
   }
}

.dual-banners-images {
   display: flex;
}

@media only screen and (min-width: 768px) {
   .dual-banners-images {
      flex-direction: column;
   }
}

.dual-banners-element {
   width: 100%;

   padding: 0 1rem;
   margin: 0.3rem 0;

   display: flex;
}

@media only screen and (min-width: 768px) {
   .dual-banners-element {
      padding: 0.5rem 0;
      margin: 0;
   }
}

.dual-banners-element > img {
   width: 100%;

   border-radius: 1rem;
   
   background-color: var(--element-shadow-color);

   box-shadow: var(--element-shadow);

   aspect-ratio: 5 / 4;
   object-fit: fill;
}

@media only screen and (min-width: 768px) {
   .dual-banners-element > img {
      aspect-ratio: 5 / 2;
   }
}

@media only screen and (min-width: 768px) {
   .dual-banners-element > img {
      box-shadow: none;
   
      background-color: transparent;
   }
}

.desktop-banners-container {
   aspect-ratio: 114 / 41;

   z-index: -1;
}