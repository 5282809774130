.auth-section {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
}

@media only screen and (min-width: 768px) {
   .auth-section {
      min-height: 100vh;

      background-color: var(--dark-main-color);
   }
}

.auth-section.business {
   background: rgb(0,22,99);
   background: linear-gradient(180deg, var(--dark-main-color) 0%, var(--dark-main-color) 30%, rgb(20, 46, 139) 50%, var(--background-color) 90%);
}

@media only screen and (min-width: 768px) {
   .auth-section.business {
      background: unset;

      background-color: var(--dark-main-color);
   }
}

.auth-container {
   padding: 2rem 0;

   display: flex;
   flex-direction: column;
   align-items: center;

   flex-grow: 1;
}

@media only screen and (min-width: 768px) {
   .auth-container {
      padding: 5rem 0;
   }
}

.auth-back {
   display: none;
}

@media only screen and (min-width: 768px) {
   .auth-back {
      display: block;

      width: 100%;
   }
}

.auth-back-btn {
   display: flex;
   align-items: center;
   column-gap: 1.5rem;

   cursor: pointer;
}

.auth-back-btn > svg {
   background-color: #FFFFFF;

   width: 1.6rem;
   height: 1.6rem;
   
   padding: 0.5rem;

   border-radius: 50%;

   display: flex;
   align-items: center;
   justify-content: center;

   line-height: 100%;
}

.auth-back-btn > p {
   line-height: 100%;

   color: #FFFFFF;
}

.auth-logo {
   width: 50%;

   flex-shrink: 0;

   margin-bottom: 5rem;
}

@media only screen and (min-width: 768px) {
   .auth-logo {
      width: 20%;
      max-width: 27rem;
   }
}

.auth-logo.recovery {
   margin-bottom: 10rem;
}

@media only screen and (min-width: 768px) {
   .auth-logo.recovery {
      margin-bottom: 5rem;
   }
}

.auth-logo > img {
   width: 100%;
}

.auth-form-container {
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .auth-form-container {
      width: 50%;
      max-width: 67.5rem;

      border-radius: 0.5rem;

      padding: 2.5rem 3rem;

      background-color: #FFFFFF;
   }
}

.auth-title {
   font-size: 2rem;
   font-weight: 500;
   letter-spacing: 0.1rem;
   text-transform: uppercase;

   color: var(--grey);

   margin-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
   .auth-title {
      color: var(--dark-main-color);
   }
}

.business .auth-title {
   color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .business .auth-title {
      color: var(--dark-main-color);
   }
}

.auth-form {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1.5rem;

   width: 80%;

   margin-bottom: 4rem;
}

.auth-form.separated {
   row-gap: 2rem;
}

.auth-error {
   color: var(--secondary-color);

   line-height: 100%;
}

.auth-success {
   text-align: center;

   color: var(--green);
}

.auth-form-group {
   width: 100%;

   flex: 1 0 0px;

   display: flex;
   flex-direction: column;
   align-items: flex-start;
   row-gap: 0.5rem;
}

.auth-form-group > p.login-error {
   color: var(--secondary-color);

   font-size: 1.4rem;
   line-height: 100%;
}

.auth-form > input,
.auth-form-group > input,
.auth-form > select,
.auth-form-group > select {
   background-color: var(--input-grey);

   padding: 0.7rem 0;

   border: var(--input-border);
   border-radius: 1rem;

   font-size: 1.8rem;
   font-weight: 300;
   line-height: 100%;
   text-align: center;

   width: 100%;

   flex-grow: 1;

   transition: border-color 0.2s linear;
}

.auth-form > input,
.auth-form-group > input {
   padding: 0.7rem 1.5rem;
}

.auth-form-group.error > input {
   border-color: var(--secondary-color);
}

.auth-form-row {
   display: flex;
   flex-direction: column;
   gap: 1.5rem;

   width: 100%;
}

@media only screen and (min-width: 768px) {
   .auth-form-row {
      flex-direction: row;
      align-items: flex-start;
   }
}

.auth-form > button {
   background-color: var(--dark-main-color);

   color: #FFFFFF;

   width: 60%;

   padding: 1rem 1.5rem;

   border: var(--input-border);
   border-radius: 1rem;

   font-weight: 500;
   letter-spacing: 0.05rem;
}

@media only screen and (min-width: 768px) {
   .auth-form > button {
      transition: background-color 0.1s linear;
   }

   .auth-form > button:hover {
      background-color: var(--main-color);
   }
}

.auth-form > button:not(:disabled) {
   cursor: pointer;
}

.auth-form > button:disabled {
   background-color: var(--light-main-color);
}

.auth-radios {
   display: flex;
   justify-content: space-between;

   width: 100%;
}

.auth-radio {
   width: 46%;
   height: 3.5rem;

   position: relative;
}

.auth-radio > input {
   display: none;
}

.auth-radio > .auth-radio-btn {
   width: 100%;
   height: 100%;

   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;

   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 1rem;

   border-radius: 0.5rem;

   border: var(--input-border);

   color: var(--dark-main-color);
   background-color: var(--input-grey);

   cursor: pointer;

   transition: color 0.1s linear, background-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .auth-radio > .auth-radio-btn:hover {
      color: #FFFFFF;
      background-color: var(--main-color);
   }
}

.auth-radio > input:checked ~ .auth-radio-btn {
   color: #FFFFFF;
   
   background-color: var(--dark-main-color);
}

.auth-radio-btn > svg {
   line-height: 100%;

   font-size: 1.8rem;
}

.auth-radio-btn > p {
   line-height: 100%;

   letter-spacing: 0.03rem;
}

.auth-function {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .auth-function {
      flex-direction: row;
      column-gap: 0.5rem;
   }
}

.auth-function:not(:last-child) {
   margin-bottom: 2rem;
}

.auth-function > p {
   line-height: 100%;

   color: var(--light-grey);
}

.auth-function > p.light {
   color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .auth-function > p.light {
      color: var(--light-grey);
   }
}

.auth-function > .functional {
   font-weight: 500;
   line-height: 100%;
}

.functional.red {
   color: var(--secondary-color);
}

.functional.blue {
   color: var(--main-color);
}