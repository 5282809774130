.faq-container {
   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}

@media only screen and (min-width: 768px) {
   .faq-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: "srch srch srch"
                           "main main main"
                           "main main main";
      gap: 6rem;
   }

   .faq-container:has(> .faq-sidebar-container) {
      grid-template-areas: "srch srch srch"
                           "side main main"
                           "side main main";
   }
}

.faq-search {
   position: relative;

   grid-area: srch;
}

@media only screen and (min-width: 768px) {
   .faq-search {
      width: 60%;
      max-width: 65rem;

      margin: 0 auto;
   }
}

.faq-search > input {
   background-color: var(--soft-grey);

   padding: 0.75rem 5rem 0.75rem 2rem;

   border-radius: 2rem;

   width: 100%;

   color: var(--light-grey);
   
   font-size: 1.8rem;
   font-weight: 300;
   text-align: center;

   border: var(--input-border);
}

.faq-search > button {
   color: var(--light-grey);

   font-size: 2.2rem;

   display: flex;
   align-items: center;
   justify-content: center;

   position: absolute;
   top: 0;
   right: 0;

   z-index: 1;

   width: 5rem;
   height: 100%;
   
   transition: color 0.2s ease;
}

.faq-search > button:not(:disabled):hover {
   cursor: pointer;

   color: var(--main-color);
}

.faq-sidebar-container {
   grid-area: side;

   display: none;
}

@media only screen and (min-width: 768px) {
   .faq-sidebar-container {
      display: block;
   }
}

.faq-sidebar {
   padding: 1rem 1.5rem;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   background-color: var(--soft-main-color);

   border-radius: 2rem;
}

.faq-sidebar-title {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--secondary-color);

   margin-bottom: 1rem;
}

.faq-sidebar-element {
   line-height: 100%;
   text-align: start;

   color: var(--dark-main-color);

   cursor: pointer;

   transition: color 0.2s ease-in-out;
}

.faq-sidebar-element:hover {
   color: var(--main-color);
}

.faq {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   grid-area: main;
}

.faq-empty {
   display: flex;
   align-items: center;
   column-gap: 2rem;

   margin: 0 auto;
}

.faq-empty > svg {
   font-size: 5.6rem;
}

.faq-empty-body {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1rem;
}

.faq-empty-body > p {
   font-size: 2rem;
}

.faq-empty-body > span {
   font-size: 2.4rem;
   font-weight: 500;
}

.faq-element {
   display: flex;
   flex-direction: column;

   border-radius: 2rem;

   transition: box-shadow 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .faq-element {
      background-color: var(--soft-main-color);

      transition: background-color 0.2s linear;
   }
}

.faq-element.open {
   border: var(--element-border);
   
   box-shadow: var(--element-shadow);
}

@media only screen and (min-width: 768px) {
   .faq-element.open {
      border: none;

      box-shadow: none;

      background-color: var(--input-grey);
   }
}

.faq-element-header {
   display: flex;
   align-items: stretch;

   border-radius: 2rem 2rem 0 0;

   transition: background-color 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .faq-element-header {
      cursor: pointer;
   }
}

.open .faq-element-header {
   background-color: var(--light-secondary-color);
}

@media only screen and (min-width: 768px) {
   .open .faq-element-header {
      background-color: inherit;
   }
}

.faq-element-number {
   font-size: 2rem;
   line-height: 100%;

   width: 4rem;
   height: 4rem;

   border-radius: 50%;

   display: flex;
   align-items: center;
   justify-content: center;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   transition: background-color 0.2s linear, color 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .faq-element-number {
      display: none;
   }
}

.open .faq-element-number {
   background-color: var(--light-secondary-color);
}

.faq-element-title {
   width: calc(100% - 4rem);

   padding: 0 0.5rem;
   
   display: flex;
   align-items: center;
   justify-content: center;

   font-size: 1.4rem;
   text-align: center;
   line-height: 100%;

   color: var(--main-color);

   transition: color 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .faq-element-title {
      width: 100%;

      padding: 2rem 1.5rem;

      font-size: 1.8rem;
      font-weight: 500;

      justify-content: flex-start;

      text-align: start;

      color: var(--dark-main-color);
   }
}

.open .faq-element-title {
   color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .open .faq-element-title {
      color: var(--main-color);
   }
}

.faq-element-body {
   background-color: var(--input-grey);

   border-radius: 0 0 2rem 2rem;

   height: 0;

   overflow: hidden;

   transition: height 0.2s linear;
}

@media only screen and (min-width: 768px) {
   .faq-element-body {
      background-color: var(--soft-main-color);

      transition: height 0.2s linear, background-color 0.2s linear;
   }
}

@media only screen and (min-width: 768px) {
   .open .faq-element-body {
      background-color: var(--input-grey);
   }
}

/* REMINDER: .open .faq-element-body está siendo animado en el componente FaqElement */

.faq-element-body > p {
   font-size: 1.4rem;
   text-align: justify;
   text-justify: inter-word;
   
   padding: 1rem 1.5rem;
}

@media only screen and (min-width: 768px) {
   .faq-element-body > p {
      font-size: 1.6rem;
   }
}