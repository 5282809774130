.social-title {
   font-size: 2.4rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;
   text-align: center;

   color: var(--main-color);
}

.social-medias {
   margin-top: 2rem;

   display: flex;
   justify-content: space-evenly;
   align-items: center;
}

.social-medias > a {
   font-size: 5rem;

   color: var(--light-main-color);

   transition: color 0.3s ease;
}

@media only screen and (min-width: 768px) {
   .social-medias > a {
      font-size: 8rem;
   }
}

.social-medias > a:hover {
   color: var(--main-color);
}