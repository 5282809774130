.cart-title {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

@media only screen and (min-width: 768px) {
   .cart-title {
      justify-content: center;

      position: relative;

      max-width: 130rem;
      
      margin: 0 auto;
   }
}

.cart-title-text {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;
}

.cart-empty-btn {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   color: var(--secondary-color);

   font-size: 1.4rem;
}

@media only screen and (min-width: 768px) {
   .cart-empty-btn {
      position: absolute;
      right: 1rem;

      padding: 0.5rem 1rem;

      border-radius: 1.5rem;

      cursor: pointer;

      transition: background-color 0.2s linear, color 0.2s linear;
   }

   .cart-empty-btn:hover {
      color: #FFFFFF;
      background-color: var(--secondary-color);
   }
}

.cart-empty-btn > svg,
.cart-empty-btn > p {
   line-height: 100%;
}

.cart {
   display: flex;
   flex-direction: column;

   padding-bottom: 9.5rem;
}

@media only screen and (min-width: 768px) {
   .cart {
      display: grid;
      grid-template-columns: 5fr 2fr;
      gap: 3rem;

      max-width: 130rem;
      
      margin: 0 auto;
      padding-bottom: 0;
   }
}

.cart-loading {
   padding: 10rem 0;
}

.cart-list {
   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .cart-list {
      row-gap: 2rem;
   }
}

.cart-empty {
   padding: 5rem 0;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   font-size: 2.4rem;
   font-weight: 500;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .cart-empty {
      padding: 10rem 0;
   }
}

.cart-header {
   display: none;
}

@media only screen and (min-width: 992px) {
   .cart-header {
      padding: 0 1rem;

      display: grid;
      grid-template-columns: 1fr 2fr repeat(3, 1fr);
      column-gap: 1rem;

      color: var(--light-grey);
   }
}

.cart-header > p {
   text-align: center
}

@import url('./cart-element.css');

@import url('./cart-button.css');

@import url('./cart-sidebar.css');

@import url('./cart-toast.css');