.order-confirmation-section {
   flex-grow: 1;

   display: flex;
   flex-direction: column;
}

.order-confirmation {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   row-gap: 2rem;

   flex-grow: 1;
}

.order-confirmation-element {
   display: flex;
   align-items: center;
   column-gap: 2rem;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-element {
      column-gap: 4rem;

      width: 70%;
      max-width: 75rem;
   
      margin: 0 auto;
   }
}

.order-confirmation-element > svg {
   font-size: 12rem;

   color: var(--green);

   flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-element > svg {
      font-size: 16rem;
   }
}

.order-confirmation-element > svg.error {
   color: var(--secondary-color);
}

.order-confirmation-element-body {
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 2rem;

   flex-grow: 1;
}

.order-confirmation-element-body > p {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-element-body > p {
      font-size: 3.2rem;
   }
}

.order-confirmation-links {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-links {
      flex-direction: row;
      justify-content: center;
      column-gap: 1rem;
   }
}

.order-confirmation-link {
   background-color: var(--light-grey);

   color: #FFFFFF;

   font-size: 2rem;
   text-align: center;
   line-height: 100%;

   padding: 1rem 2rem;

   border-radius: 0.5rem;

   cursor: pointer;

   transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-link {
      padding: 0.5rem 2rem;
   }
}

.order-confirmation-link:hover {
   background-color: var(--grey);
}

.order-confirmation-link.main {
   background-color: var(--main-color);
}

.order-confirmation-link.main:hover {
   background-color: var(--dark-main-color);
}

.order-confirmation-link.green {
   background-color: var(--green);
}

.order-confirmation-link.green:hover {
   background-color: var(--dark-green);
}



/* Modal */

.order-confirmation-modal {
   width: 80%;

   padding: 1.5rem 2rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   border-radius: 2rem;

   box-shadow: var(--hud-shadow);

   position: relative;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-modal {
      width: 30%;
      max-width: 50rem;

      padding: 1.5rem 6rem;
   }
}

.order-confirmation-modal > p {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--main-color);
}

@media only screen and (min-width: 768px) {
   .order-confirmation-modal > p {
      text-align: center;
   }
}

.order-confirmation-close {
   position: absolute;
   top: 1rem;
   right: 1.5rem;

   font-size: 1.4rem;
   line-height: 80%;

   display: flex;
   align-items: center;
   justify-content: center;

   width: 2.5rem;
   height: 2.5rem;

   border-radius: 50%;

   color: #FFFFFF;
   background-color: var(--secondary-color);

   cursor: pointer;

   transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .order-confirmation-close {
      top: 1.5rem;
      right: 2rem;
   
      font-size: 2rem;
   
      width: 3rem;
      height: 3rem;
   }
}

.order-confirmation-modal-separator {
   margin: 0.5rem 0;

   border-top: var(--input-border);
}

.order-confirmation-modal-body {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.order-confirmation-modal-body > button {
   padding: 1rem;
   margin-top: 2rem;

   font-size: 1.8rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   border-radius: 0.5rem;

   transition: background-color 0.2s ease;
}

.order-confirmation-modal-body > button:not(:disabled) {
   cursor: pointer;
}

.order-confirmation-modal-body > button:hover {
   background-color: var(--dark-main-color);
}

.order-confirmation-modal-body > button:disabled {
   background-color: var(--light-main-color);
}

.order-confirmation-modal-select {
   display: flex;
   flex-direction: column;
}

.order-confirmation-modal-select > label {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   margin-left: 0.5rem;
   margin-bottom: 0.3rem;
}

.order-confirmation-modal-select > select {
   padding: 0.5rem 1rem;

   background-color: transparent;

   border: 0.15rem solid var(--dark-main-color);
   border-radius: 0.5rem;

   cursor: pointer;
}

.order-confirmation-modal-status {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--main-color);

   margin: 1.5rem 0;
}

.order-confirmation-modal-status.error {
   color: var(--secondary-color);
}



/* Dispatch coordination modal */

.dispatch-coordination-modal {
   width: 80%;

   padding: 1.5rem 2rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   border-radius: 2rem;

   box-shadow: var(--hud-shadow);

   position: relative;
}

@media only screen and (min-width: 768px) {
   .dispatch-coordination-modal {
      width: 30%;
      max-width: 50rem;

      padding: 3rem 6rem;
   }
}