.marquee-ribbon-container {
   background-color: var(--dark-main-color);

   padding: 0.4rem 1rem;

   font-size: 1.4rem;
   font-weight: 300;

   z-index: var(--section-index);

   color: #FFFFFF;
}

.marquee-ribbon-element {
   margin: 0 5rem;

   font-weight: 500;
}