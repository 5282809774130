.generated-element {
   display: flex;
   column-gap: 1rem;

   padding: 1rem 0.2rem;

   background-color: #FFFFFF;

   border: 0.1rem solid transparent;
   border-radius: 0.5rem;
}

.generated-element.error {
   border-color: var(--dark-secondary-color);
}

@media only screen and (min-width: 992px) {
   .generated-element {
      display: grid;
      grid-template-columns: 1fr 2fr repeat(3, 1fr);
      align-items: center;

      padding: 0.5rem 1rem;

      border: 0.1rem solid var(--input-grey);
      border-radius: 1rem;

      position: relative;
   }
}

@media only screen and (max-width: 991px) {
   .generated-element:not(:last-child) {
      border-bottom: 0.1rem solid var(--input-grey);
   }
}

.generated-element-imgs {
   width: calc(30% - 0.5rem);

   display: flex;
   flex-direction: column;
   justify-content: center;
}

@media only screen and (min-width: 992px) {
   .generated-element-imgs {
      width: 100%;
   }
}

.generated-element-img {
   width: 100%;

   display: flex;
}

.generated-element-img > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.generated-element-brand-img {
   width: 70%;

   margin: 0 auto;

   display: flex;
}

.generated-element-brand-img > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}

.generated-element-data {
   width: calc(70% - 0.5rem);

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.generated-element-info {
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   row-gap: 0.5rem;
}

.generated-element-info-text {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.generated-element-info-text > a {
   line-height: 100%;
   letter-spacing: 0.05rem;
   text-transform: uppercase;
}

.generated-element-info-text > span {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
   
   color: var(--light-grey);
}

.generated-element-info-prices {
   display: flex;
   flex-direction: column;
}

.generated-element-info-price {
   font-size: 1.6rem;
   font-weight: 500;
   line-height: 100%;

   margin-bottom: 1rem;
}

@media only screen and (min-width: 992px) {
   .generated-element-info-price {
      font-weight: 400;
      text-align: center;
   }
}

.generated-element-info-price:has(> span) {
   font-weight: 400;
}

.generated-element-info-price > span {
   font-weight: 500;
}

.generated-element-info-fee {
   display: flex;
   column-gap: 0.5rem;

   font-size: 1.4rem;
}

@media only screen and (min-width: 992px) {
   .generated-element-info-fee {
      flex-direction: column;
      align-items: center;
   }
}

.generated-element-info-fee > span {
   line-height: 100%;
   color: var(--grey);
}

.generated-element-info-fee > p {
   font-weight: 500;
   line-height: 100%;
}

.generated-element-input {
   width: 100%;

   margin-top: auto;

   display: flex;
   justify-content: space-between;
   align-items: center;
}

.generated-element-input-qty {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;
}

@media only screen and (min-width: 992px) {
   .generated-element-input-qty {
      justify-content: center;
   }
}

.generated-element-input-qty > svg {
   color: white;

   background-color: var(--dark-main-color);

   width: 1.4rem;
   height: 1.4rem;

   padding: 0.5rem;

   border-radius: 50%;

   display: flex;
   align-items: center;
   justify-content: center;
}

@media only screen and (min-width: 992px) {
   .generated-element-input-qty > svg {
      cursor: pointer;

      color: var(--black);
      background-color: transparent;

      border: 0.1rem solid var(--input-grey);

      transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
   }

   .generated-element-input-qty > svg:hover {
      color: #FFFFFF;
      background-color: var(--dark-main-color);
      border-color: var(--dark-main-color);
   }
}

.generated-element-input-qty > p {
   font-size: 1.8rem;
   font-weight: 300;
   line-height: 100%;

   width: 3.5rem;

   text-align: center;
}

.generated-element-subtotal {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

.generated-element-subtotal > :is(p, span) {
   line-height: 100%;
}

.generated-element-subtotal > span {
   font-weight: 500;

   color: var(--main-color);
}

.generated-element-buttons {
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

.generated-element-alert-text {
   font-size: 1.2rem;
   color: var(--dark-secondary-color);
   margin-right: auto;
}

.generated-element-input-delete {
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 100%;

   color: var(--secondary-color);
}

@media only screen and (min-width: 992px) {
   .generated-element-input-delete {
      position: absolute;
      right: 1rem;
      bottom: 1rem;

      cursor: pointer;

      transition: color 0.2s linear;
   }

   .generated-element-input-delete:hover {
      color: var(--dark-secondary-color);
   }
}

.generated-element-input-delete:has(> svg) {
   padding: 0.5rem 1rem;
   
   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   border-radius: 1.5rem;

   color: #FFFFFF;
   background-color: var(--secondary-color);
}

.generated-element-input-delete > svg {
   font-size: 1.6rem;
}

.generated-element-input-delete > p {
   line-height: 80%;
}



/* Desktop */

.generated-element-info-total {
   font-weight: 500;
   line-height: 100%;
   text-align: center;
}