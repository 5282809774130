.orders {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .orders {
      row-gap: 0;

      background-color: #FFFFFF;
   }

   .orders > * {
      border-left: 0.1rem solid var(--dark-main-color)!important;
      border-right: 0.1rem solid var(--dark-main-color)!important;
   }

   .orders > *:first-child {
      border-top: 0.1rem solid var(--dark-main-color)!important;
      border-start-start-radius: 1rem;
      border-start-end-radius: 1rem;
   }

   .orders > *:last-child {
      border-bottom: 0.1rem solid var(--dark-main-color)!important;
      border-end-start-radius: 1rem;
      border-end-end-radius: 1rem;
   }
}

.orders-header {
   width: 100%;

   padding: 1.5rem 0;

   display: none;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

   color: #FFFFFF;
   background-color: var(--dark-main-color);
}

@media only screen and (min-width: 786px) {
   .orders-header {
      display: grid;
   }
}

.orders-header-element {
   width: 100%;

   font-size: 1.8rem;
   font-weight: 300;
   text-align: center;
}

.orders-loading {
   padding: 10rem 0;
}

.order-element {
   width: 100%;

   padding: 1rem 1.5rem;

   border: 0.1rem solid var(--grey);
   border-radius: 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .order-element {
      padding: 1.5rem 0;

      border: none;
      border-radius: 0;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      position: relative;
   }

   .order-element:not(:last-child)::before {
      content: '';

      position: absolute;
      right: 2%;
      bottom: 0;
      left: 2%;

      height: 0.1rem;

      background-color: var(--input-grey);
   }

   .order-element > p {
      font-size: 1.8rem;
      font-weight: 300;
      
      display: flex;
      align-items: center;
      justify-content: center;
   }
}



/* Only desktop */

p.order-element-price {
   font-weight: 400;
}

p.order-element-status {
   font-weight: 400;
}

p.order-element-status.green {
   color: var(--green);
}

p.order-element-status.blue {
   color: var(--light-main-color);
}

.order-element-buttons {
   width: 100%;

   display: flex;
   justify-content: center;
   align-items: center;
}

.order-element-button {
   padding: 0.5rem 1.5rem;

   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   border: 0.1rem solid transparent;

   cursor: pointer;
   
   font-size: 1.8rem;

   transition: border-radius 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.order-element-button:hover {
   border-radius: 1.5rem;

   color: #FFFFFF;
   background-color: var(--main-color);
}

.order-element-button > p {
   line-height: 100%;
}



/* Only mobile */

.order-element-row {
   display: flex;
   justify-content: space-between;
}

.order-element-row p {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
}

.order-element-row span {
   font-weight: 400;
   line-height: 100%;
}

.order-element-row span.red {
   color: var(--secondary-color);
}

.order-element-row span.blue {
   color: var(--main-color);
}