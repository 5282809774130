.product-offer-tags-container {
   grid-area: tags;

   overflow: hidden;

   padding: 1rem;
}

@media only screen and (min-width: 768px) {
   .product-offer-tags-container {
      padding: 2.5rem;
   }
}

@media only screen and (min-width: 992px) {
   .product-offer-tags-container {
      margin-bottom: 0;

      padding: 1rem 1rem 1rem 0;
   }
}

.product-offer-tags {
   width: 100%;

   display: flex;
   align-items: center;
   gap: 1rem;

   overflow-y: auto;

   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
}

.product-offer-tags::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
}

.product-offer-tag {
   flex-shrink: 0;

   width: 6rem;

   margin: 0 auto;

   display: flex;
}

@media only screen and (min-width: 768px) {
   .product-offer-tag {
      width: 9rem;
   }
}

.product-offer-tag > img {
   width: 100%;

   aspect-ratio:  1 / 1;
   object-fit: contain;
}