.product-list-container {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
}

.product-list {
   width: 100%;

   display: grid;
   grid-template-columns: repeat(5, 1fr);
   /* grid-auto-rows: 1fr; */
   gap: 2rem;
}

.product-list-empty {
   width: 100%;

   padding: 5rem 0;

   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 2rem;
}

.product-list-empty > svg {
   font-size: 5.6rem;
}

.product-list-empty-data {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 1rem;
}

.product-list-empty-data:not(.message) > p {
   font-size: 2rem;
   text-align: center;
}

.product-list-empty-data.message > p {
   font-size: 2.4rem;
   font-weight: 500;
   text-align: center;
}

.product-list-empty-data:not(.message) > span {
   font-size: 2.4rem;
   font-weight: 500;
}

.product-list-empty-data.message > span {
   font-size: 2rem;
}