.products-grid-title {
   width: 100%;

   padding-bottom: 0.5rem;
   margin-bottom: 2rem;

   border-bottom: 0.2rem solid var(--dark-main-color);

   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;
}

.products-grid {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-auto-rows: 1fr;
   gap: 1rem;

   width: 100%;
}

.products-grid-span {
   grid-column: 1 / 3;
}