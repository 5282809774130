.cart-sidebar {
   display: none;
}

@media only screen and (min-width: 768px) {
   .cart-sidebar {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
   }
}

.cart-sidebar-element {
   width: 100%;

   padding: 2rem 1.5rem;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1.5rem;
   
   background-color: #FFFFFF;

   border: 0.1rem solid var(--input-grey);
   border-radius: 1rem;
}

.cart-sidebar-element.empty {
   display: none;
}

.cart-sidebar-element.coupon {
   margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
   .cart-sidebar-element.coupon {
      margin-top: 0;
   }
}

.cart-sidebar-title {
   font-size: 1.6rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);

   margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
   .cart-sidebar-title {
      font-size: 1.4rem;

      margin-bottom: 2rem;
   }
}

.cart-sidebar-title-alt {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--secondary-color);
}

.cart-sidebar-input {
   width: 90%;

   padding: 1.5rem 0;

   line-height: 100%;
   text-align: center;
   text-transform: uppercase;

   border-radius: 1rem;
   border: 0.1rem solid var(--input-grey);
}

@media only screen and (min-width: 768px) {
   .cart-sidebar-input {
      width: 80%;
   }
}

.cart-sidebar-input:disabled {
   background-color: #FFFFFF;
}

.cart-sidebar-submit {
   width: 90%;

   padding: 1rem 0;

   border-radius: 0.5rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   font-size: 1.8rem;
   line-height: 100%;
   text-transform: uppercase;
   
   cursor: pointer;

   transition: background-color 0.2s ease-in-out;
}

@media only screen and (min-width: 768px) {
   .cart-sidebar-submit {
      width: 80%;
   }
}

.cart-sidebar-submit:hover {
   background-color: var(--dark-main-color);
}

.cart-sidebar-submit:disabled {
   background-color: var(--light-main-color);
   
   cursor: inherit;
}

.cart-sidebar-message {
   font-size: 1.6rem;
   font-weight: 300;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .cart-sidebar-message {
      font-size: 1.4rem;
   }
}

.cart-sidebar-message.red {
   color: var(--light-secondary-color);
}

.cart-sidebar-message > span {
   font-weight: 400;
   color: var(--main-color);
}

.cart-sidebar-row {
   width: 100%;

   display: flex;
   justify-content: space-between;
}

.cart-sidebar-row > p {
   font-weight: 500;
   line-height: 100%;
}

.cart-sidebar-row > p > span {
   color: var(--main-color);
}

.cart-sidebar-row > span {
   line-height: 100%;
}

.cart-sidebar-separator {
   width: 100%;

   border-top: 0.1rem solid var(--input-grey);
}

.cart-sidebar-total {
   font-size: 2.8rem;
   font-weight: 500;
   line-height: 100%;
}

.cart-sidebar-button {
   position: relative;

   padding: 1.5rem 1rem;

   display: flex;
   align-items: center;
   justify-content: center;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   border-radius: 1rem;

   cursor: pointer;

   transition: background-color 0.2s linear;
}

.cart-sidebar-button:hover {
   background-color: var(--main-color);
}

.cart-sidebar-button.empty {
   display: none;
}

.cart-sidebar-button > p {
   font-size: 1.8rem;
   line-height: 100%;
   letter-spacing: 0.1rem;
   text-transform: uppercase;
}

.cart-sidebar-button > svg {
   position: absolute;
   right: 1rem;

   font-size: 2.4rem;
}