.warranties-input-group {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.warranties-input-group > label {
   margin-left: 1rem;

   color: var(--light-grey);

   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;

   transition: color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .warranties-input-group > label {
      font-size: 1.4rem;
   }
}

.warranties-input-group.error > label {
   color: var(--secondary-color);
}

.warranties-input-group > input,
.warranties-input-group > select,
.warranties-input-group > textarea {
   font-weight: 400;
   text-align: center;

   padding: 0.7rem 1rem;

   border: var(--input-border);
   border-radius: 0.5rem;

   transition: border-color 0.1s linear, color 0.1s linear;
}

.warranties-input-group > textarea {
   resize: none;

   text-align: start;
}

.warranties-input-group > input[type=number]::-webkit-outer-spin-button,
.warranties-input-group > input[type=number]::-webkit-inner-spin-button,
.warranties-input-group > input[type=number] {
   appearance: textfield;
   -moz-appearance: textfield;
   -webkit-appearance: none;
}

.warranties-input-group.error > input,
.warranties-input-group.error > select,
.warranties-input-group.error > textarea {
   border-color: var(--secondary-color);
   color: var(--secondary-color);
}

.warranties-input-group.error > .warranties-input-file {
   border-color: var(--secondary-color);
}

.warranties-input-group > input::placeholder,
.warranties-input-group > textarea::placeholder {
   color: var(--light-grey);
}

.warranties-input-group.placeholder-selected > select {
   color: var(--light-grey);
}

.warranties-input-group.placeholder-selected > select > option:not(:disabled) {
   color: var(--black);
}

.warranties-input-group > p {
   width: 100%;

   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
   text-align: left;

   color: var(--secondary-color);

   margin-left: 1rem;
}



/* Size classes */

.full-row {
   grid-column: 1 / 3;
}