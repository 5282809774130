.products-filter {
   width: 100%;

   display: flex;
   justify-content: space-between;
}

@media only screen and (min-width: 768px) {
   .products-filter {
      padding: 1rem 2rem;

      background-color: var(--input-grey);

      border-radius: 0.5rem;

      justify-content: flex-start;
      column-gap: 3rem;
   }
}

.products-filter-element-container {
   width: 27%;
}

.products-filter-element {
   font-weight: 300;
   text-align: center;

   color: var(--light-grey);
   background-color: var(--input-grey);

   width: 100%;

   padding: 0.5rem 0;

   border: var(--input-border);
   border-radius: 0.5rem;

   transition: color 0.1s linear, font-weight 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .products-filter-element-container {
      width: 18rem;

      position: relative;

      margin-top: 1rem;
   }

   .products-filter-element {
      background-color: #FFFFFF;
   }

   .products-filter-element-container::after {
      content: attr(data-tooltip);

      position: absolute;
      top: -1.2rem;
      left: 1rem;

      color: var(--grey);

      font-size: 1.2rem;
   }
}

.products-filter-element:focus {
   color: var(--soft-dark-main-color);

   font-weight: 400;
}

.products-filter-button {
   color: #FFFFFF;
   background-color: var(--secondary-color);

   padding: 0 1rem;

   width: 10%;

   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 0.5rem;

   border-radius: 0.5rem;

   transition: background-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .products-filter-button {
      width: unset;

      cursor: pointer;
   }

   .products-filter-button:not(:disabled):hover {
      background-color: var(--dark-secondary-color);
   }
}

.products-filter-button > p {
   display: none;
}

@media only screen and (min-width: 768px) {
   .products-filter-button > p {
      display: block;
   }
}

.products-filter-button > svg {
   font-size: 1.4rem;
   line-height: 100%;
}

.products-filter-button:disabled {
   background-color: var(--soft-secondary-color);
}



/* Desktop */

.products-filter-desktop {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.products-filter-tooltip {
   display: none;

   margin: auto 0;
}

@media only screen and (min-width: 768px) {
   .products-filter-tooltip {
      display: block;
   }
}