.profile-nav-container {
   width: var(--section-width);

   margin: var(--section-margin);

   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .profile-nav-container {
      grid-area: side;

      width: 100%;
   
      margin: 0;
   
      display: flex;
      flex-direction: column;
   }
}

.profile-nav {
   width: 100%;

   padding: 0 1.5rem;

   display: flex;
   flex-direction: column;

   background-color: #FFFFFF;

   border: var(--input-border);
   border-radius: 1rem;
}

.profile-nav-element {
   padding: 1.5rem 0;

   display: flex;
   align-items: center;
   column-gap: 1rem;

   color: #849caa;

   transition: color 0.2s linear;
}

.profile-nav-element:not(.active, .profile-nav-button):hover {
   color: var(--grey);
}

.profile-nav-element:not(:last-child) {
   border-bottom: 0.1rem solid var(--input-grey);
}

.profile-nav-element.active {
   color: var(--soft-dark-main-color);
}

.profile-nav-button {
   color: var(--secondary-color);
   
   cursor: pointer;

   transition: color 0.2s linear;
}

.profile-nav-button:hover {
   color: var(--dark-secondary-color);
}

.profile-nav-element > svg {
   font-size: 1.9rem;

   min-width: 3rem;

   display: flex;
   align-items: center;
   justify-content: center;

   flex-shrink: 0;
}

.profile-nav-element > p {
   line-height: 100%;
   text-align: start;

   flex-grow: 1;
}