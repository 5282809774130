

/* Alert */

.page-construction-modal {
   background-color: #FFFFFF;

   border-radius: 0.5rem;

   display: flex;
   flex-direction: column;
   align-items: center;

   padding: 2rem;
}

@media only screen and (min-width: 768px) {
   .page-construction-modal {
      padding: 2rem;
   
      width: 25%;
      max-width: 50rem;
   }
}

.page-construction-image {
   display: flex;
   margin-top: 0;
   width: 50%;
   max-width: 15rem;

   margin-bottom: 2rem;
}

.page-construction-image > img {
   width: 100%;
}

.page-construction-title {
   font-size: 1.6rem;
   text-align: center;

   margin: 0.5rem 0;
   padding: 0;
   
   color: var(--dark-main-color);
}

.page-construction-text {
   font-size: 1.4rem;
   text-align: center;
   text-transform: uppercase;

   margin: 0.5rem 0;

   color: var(--black);
}

.page-construction-btns {
   display: flex;
   flex-direction: row;
   column-gap: 1rem;

   padding: 0;
}

.page-construction-btn {
   flex: 1 1 0rem;

   margin-right: 0;
   margin-left: 0;

   padding: 1rem 0.5rem;

   font-size: 1.6rem;
   line-height: 100%;

   background-color: var(--grey);

   color: #FFFFFF;

   border-radius: 0.5rem;

   cursor: pointer;

   transition: filter 0.2s ease;
}

.page-construction-btn:hover {
   filter: brightness(0.9);
}

.page-construction-btn.link {
   background-color: var(--main-color);
}