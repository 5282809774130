.contact-container {
   position: fixed;
   right: -4.45rem;
   bottom: 15vh;

   z-index: var(--hud-index);

   transition: right 0.3s linear;
}

.contact-container.show {
   right: 0;
}

.contact {
   position: relative;

   background-color: #FFFFFF;
   
   border-top-left-radius: 1.5rem;
   border-bottom-left-radius: 1.5rem;

   transition: box-shadow 0.3s linear;
}

.show > .contact {
   box-shadow: var(--hud-shadow);
}

.contact-elements {
   display: flex;
   flex-direction: column;
   justify-content: center;

   width: 4.45rem;

   overflow: hidden;
}

.contact-element {
   width: calc(100% - 1rem);
   padding: 1rem 0;
   margin: 0 0.5rem;
   
   font-size: 2.4rem;
}

@media only screen and (min-width: 768px) {
   .contact-element {
      cursor: pointer;
   }
}

.contact-element.button {
   font-size: 1.6rem;
}

.contact-element:not(:last-child) {
   border-bottom: 0.1rem solid var(--input-grey);
}

.contact-element > svg {
   width: 100%;

   display: flex;
   justify-content: center;
   align-items: center;
}

/* Colors */

.contact-element.red {
   color: var(--dark-secondary-color);
}

.contact-element.green {
   color: var(--green);

   font-size: 2.8rem;
}

.contact-element.blue {
   color: var(--light-main-color);
}

.contact-element.grey {
   color: var(--light-grey);
}

/* Label */

.contact-button {
   position: absolute;
   right: 100%;
   top: 0;

   padding: 0.5rem 0.7rem 0.5rem 0.8rem;

   color: #FFFFFF;
   background-color: var(--secondary-color);
   
   border-top-left-radius: 1.5rem;
   border-bottom-left-radius: 1.5rem;

   box-shadow: -0.25rem 0 0.3rem 0.1rem rgba(0, 0, 0, 0.1);

   overflow: hidden;
   
   transition: visibility 0.3s linear, opacity 0.3s linear, background-color 0.3s linear;
}

@media only screen and (min-width: 768px) {
   .contact-button {
      cursor: pointer;
   }

   .contact-button:hover {
      background-color: var(--dark-secondary-color);
   }
}

.contact-button.hidden {
   visibility: hidden;
   opacity: 0;
}

.contact-button > svg {
   display: flex;

   font-size: 1.4rem;

   transform: rotateZ(0);
}

/* Tooltip */

.contact-tooltip {
   position: absolute;
   top: 50%;
   right: 6.1rem;

   width: 24rem;

   opacity: 0;
   visibility: hidden;

   transition: opacity 0.3s ease, visibility 0.3s ease;
}

.contact-tooltip.show {
   opacity: 1;
   visibility: visible;
}

.contact-tooltip-element {
   position: relative;
}

.contact-tooltip-element::before {
   content: '';

   position: absolute;
   top: -1rem;
   right: -1rem;

   background-color: #FFFFFF;

   width: 1rem;
   height: 1rem;

   border-radius: 50%;

   box-shadow: var(--hud-shadow);
}

.contact-tooltip-close {
   position: absolute;
   top: -1rem;
   left: -1rem;

   display: flex;
   align-items: center;
   justify-content: center;

   width: 3rem;
   height: 3rem;

   border-radius: 50%;

   line-height: 80%;

   color: #FFFFFF;
   background-color: var(--secondary-color);

   cursor: pointer;

   transition: background-color 0.2s ease;
}

.contact-tooltip-close:hover {
   background-color: var(--dark-secondary-color);
}

.contact-tooltip-body {
   display: flex;
   align-items: center;
   column-gap: 1rem;

   padding: 1rem;

   background-color: #FFFFFF;

   border-radius: 1.5rem;

   box-shadow: var(--hud-shadow);

   cursor: pointer;
}

.contact-tooltip-body > svg {
   font-size: 5rem;

   color: var(--main-color);

   flex-shrink: 0;
}

.contact-tooltip-text {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;

   flex-grow: 1;
}

.contact-tooltip-text > p {
   font-size: 1.4rem;
   line-height: 100%;

   color: var(--grey);
}

.contact-tooltip-text > span {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--soft-dark-main-color);
}