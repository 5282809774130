.checkout-body {
   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;

   width: 100%;
}

@media only screen and (min-width: 768px) {
   .checkout-body {
      padding: 5rem 15%;
      
      background-color: #FFFFFF;
      
      row-gap: 3rem;

      border: 0.1rem solid var(--input-grey);
      border-radius: 1rem;
      
      /* overflow: hidden; */
   }
}

.checkout-input-group {
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 0.2rem;
}

.checkout-input-group > label {
   font-size: 1.4rem;
   font-weight: 300;

   padding-left: 1rem;

   color: var(--light-grey);

   width: 100%;

   transition: color 0.1s linear;
}

.checkout-input-group:focus-within > label {
   color: var(--secondary-color);
}

@media only screen and (min-width: 768px) {
   .checkout-input-group:focus-within > label {
      color: var(--main-color);
   }
}

.checkout-input-group.error > label {
   color: var(--secondary-color);
}

.checkout-input-group > p {
   display: none;

   font-size: 1.4rem;
   font-weight: 300;

   padding-left: 1rem;

   color: var(--secondary-color);

   width: 100%;
}

.checkout-input-group.error > p {
   display: block;
}

.checkout-input-group > input,
.checkout-input-group > select,
.checkout-input-group > textarea {
   border: 0.1rem solid var(--light-grey);
   border-radius: 0.5rem;

   padding: 0.7rem 1rem;

   font-weight: 300;
   text-align: center;

   background-color: transparent;

   transition: border-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .checkout-input-group > input,
   .checkout-input-group > select,
   .checkout-input-group > textarea {
      border-color: var(--input-grey);

      padding: 1rem 1.5rem;
      
      background-color: #FFFFFF;
   }

   .checkout-input-group > input[type=number]::-webkit-outer-spin-button,
   .checkout-input-group > input[type=number]::-webkit-inner-spin-button,
   .checkout-input-group > input[type=number] {
      appearance: textfield;
      -moz-appearance: textfield;
      -webkit-appearance: none;
   }
}

.checkout-input-group > .checkout-input-form {
   border: 0.1rem solid var(--light-grey);
   border-radius: 0.5rem;

   background-color: transparent;

   transition: border-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .checkout-input-group > .checkout-input-form {
      border-color: var(--input-grey);
      
      background-color: #FFFFFF;
   }
}

.checkout-input-form {
   display: flex;

   overflow: hidden;
}

.checkout-input-form > input {
   font-weight: 300;
   text-align: center;

   flex-grow: 1;

   padding: 0.7rem 1rem;
}

@media only screen and (min-width: 768px) {
   .checkout-input-form > input {
      flex-grow: 1;

      padding: 1rem 1.5rem;
   }
}

.checkout-input-form > button {
   color: #FFFFFF;
   background-color: var(--main-color);

   padding: 0 1.5rem;

   transition: background-color 0.2s ease;
}

.checkout-input-form > button:not(:disabled):hover {
   background-color: var(--dark-main-color);

   cursor: pointer;
}

.checkout-input-form > button:disabled {
   background-color: var(--light-main-color);
}

.checkout-input-group > textarea {
   text-align: left;
   resize: none;
}

.checkout-input-group > input:focus,
.checkout-input-group > select:focus,
.checkout-input-group > textarea:focus,
.checkout-input-group > .checkout-input-form:focus-within {
   border-color: var(--secondary-color);
}

@media only screen and (min-width: 768px) {
   .checkout-input-group > input:focus,
   .checkout-input-group > select:focus,
   .checkout-input-group > textarea:focus,
   .checkout-input-group > .checkout-input-form:focus-within {
      border-color: var(--main-color);
   }
}

.checkout-input-group.error > input,
.checkout-input-group.error > select,
.checkout-input-group.error > textarea,
.checkout-input-group.error > .checkout-input-form {
   border-color: var(--secondary-color);
}

@media only screen and (min-width: 768px) {
   .checkout-input-group.half {
      width: calc(50% - 0.75rem);
   }
}

.checkout-input-row {
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
}

@media only screen and (min-width: 768px) {
   .checkout-input-row {
      flex-direction: row;
   }
}

.checkout-info-text {
   position: relative;
}

.checkout-info-text > p {
   line-height: 100%;
   font-weight: 500;
   text-align: center;

   color: var(--light-secondary-color);

   cursor: pointer;

   transition: color 0.2s linear;
}

.checkout-info-text > p:hover {
   color: var(--secondary-color);
}

.checkout-info-text-modal {
   position: absolute;

   top: calc(100% + 1.5rem);

   padding: 1rem 2rem;

   background-color: #94B7FA;

   border-radius: 1rem;

   box-shadow: 0 0.3rem 0.3rem 0.1rem rgba(0, 0, 0, 0.15);

   opacity: 0;
   visibility: hidden;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.checkout-info-text-modal.open {
   opacity: 1;
   visibility: visible;
}

.checkout-info-text-modal::before {
   content: '';

   position: absolute;
   top: -2rem;
   right: calc(50% - 1rem);
   left: calc(50% - 1rem);

   border: 1rem solid transparent;

   border-bottom-color: #94B7FA;
}

.checkout-info-text-modal > svg {
   position: absolute;
   right: 1rem;
   top: -2rem;

   font-size: 2rem;

   cursor: pointer;

   opacity: 0;
   visibility: hidden;

   transition: color 0.2s linear, top 0.2s linear, opacity 0.1s linear 0.05s, visibility 0.1s linear;
}

.checkout-info-text-modal:hover > svg {
   top: 1rem;

   opacity: 1;
   visibility: visible;
}

.checkout-info-text-modal > svg:hover {
   color: var(--light-secondary-color);
}

.checkout-info-text-modal > span {
   font-weight: 500;
   text-align: center;

   color: var(--dark-main-color);
}



/* Paso 3 */

.checkout-separator {
   border: 0;
   border-top: 0.1rem solid var(--input-grey);

   margin: 1rem 0;
}

.checkout-coupon-input {
   display: flex;

   width: 100%;

}

.checkout-coupon-input > input {
   width: 60%;

   letter-spacing: 0.1rem;
   line-height: 100%;
   text-align: center;

   border: 0.1rem solid var(--black);
   border-right: none;
   border-top-left-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
}

.checkout-coupon-input > button {
   width: 40%;

   line-height: 100%;
   text-align: center;

   color: #FFFFFF;

   background-color: rgb(15, 202, 15);

   padding: 1rem 0.5rem;

   border: 0.1rem solid var(--black);
   border-left: none;
   border-top-right-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
}

.checkout-info {
   line-height: 100%;
   font-weight: 300;
   text-align: center;
}

.checkout-info > b {
   font-weight: 500;
}

.checkout-total {
   font-size: 2.2rem;
   line-height: 100%;

   color: var(--dark-main-color);

   text-align: center;
}

.checkout-total > span {
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-secondary-color);
}

.checkout-row {
   display: flex;
   justify-content: space-between;

   color: var(--grey);
}

.checkout-row > p {
   font-weight: 500;
   line-height: 100%;
}

.checkout-row > span {
   font-weight: 300;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .checkout-body-info {
      padding: 2rem 15%;
   
      row-gap: 1.5rem;
   }
}

.checkout-info-button {
   width: 60%;
   max-width: 30rem;

   padding: 1rem 0;
   margin: 0 auto;

   display: flex;
   align-items: center;
   justify-content: center;

   position: relative;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   border-radius: 1rem;

   cursor: pointer;

   transition: background-color 0.3s linear;
}

.checkout-info-button:hover {
   background-color: var(--main-color);
}

.checkout-info-button > p {
   font-size: 1.8rem;
   line-height: 100%;
   letter-spacing: 0.1rem;
}

.checkout-info-button > svg {
   position: absolute;
   left: 2rem;
}

.checkout-body-title {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--dark-main-color);

   margin-bottom: 1rem;
}

.checkout-tac-container {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   margin-bottom: 1rem;
}

.checkout-tac-container > p {
   font-size: 1.4rem;
   font-weight: 300;
   text-align: center;

   color: var(--secondary-color);

   width: 100%;
}

.checkout-tac-checkbox {
   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 1rem;
}

.checkout-tac-checkbox > label {
   font-size: 1.4rem;
   line-height: 100%;
   font-weight: 300;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .checkout-tac-checkbox > label {
      font-size: 1.6rem;
   }
}

.checkout-tac-link {
   font-weight: 400;

   color: var(--main-color);

   cursor: pointer;

   transition: color 0.2s ease;
}

.checkout-tac-link:hover {
   color: var(--dark-main-color);
}

.checkout-calendar-modal {
   width: 80%;
   height: 80%;

   padding: 1.5rem 2rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   border-radius: 2rem;

   box-shadow: var(--hud-shadow);

   position: relative;
}

@media only screen and (min-width: 768px) {
   .checkout-calendar-modal {
      width: 40%;
      max-width: 60rem;

      padding: 1.5rem 6rem;
   }
}

.checkout-calendar-close {
   position: absolute;
   top: 1rem;
   right: 1.5rem;

   font-size: 1.4rem;
   line-height: 80%;

   display: flex;
   align-items: center;
   justify-content: center;

   width: 2.5rem;
   height: 2.5rem;

   border-radius: 50%;

   color: #FFFFFF;
   background-color: var(--secondary-color);

   cursor: pointer;

   transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .checkout-calendar-close {
      top: 1.5rem;
      right: 2rem;
   
      font-size: 2rem;
   
      width: 3rem;
      height: 3rem;
   }
}

.checkout-calendar-info {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.checkout-calendar-title {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   margin-bottom: 1rem;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .checkout-calendar-title {
      text-align: center;
   }
}

.checkout-calendar-place {
   line-height: 100%;
   text-align: center;

   align-self: center;
}

.checkout-calendar-place.name {
   color: var(--main-color);
}

.checkout-calendar-header {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   padding-top: 1rem;

   color: var(--main-color);

   border-top: var(--input-border);
}

.checkout-calendar-list {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
   overflow-y: auto;
}

.checkout-calendar-list::-webkit-scrollbar {
   width: 0.3rem;
}

@media only screen and (min-width: 768px) {
   .checkout-calendar-list::-webkit-scrollbar {
      width: 0.7rem;
   }
}

.checkout-calendar-list::-webkit-scrollbar-thumb {
   background-color: var(--light-grey);

   border-radius: 1rem;
}

.checkout-calendar-list::-webkit-scrollbar-thumb:hover {
   background-color: var(--main-color);
}

.checkout-calendar-element {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.checkout-calendar-element:not(:last-child) {
   padding-bottom: 1rem;

   border-bottom: var(--input-border);
}

.checkout-calendar-element > p {
   font-weight: 500;
   line-height: 100%;
}

.checkout-calendar-element > span {
   line-height: 100%;
}

.checkout-calendar-element > ul {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;

   padding-left: 3rem;
   margin-top: 0.5rem;
}

.checkout-calendar-element > ul > li {
   position: relative;
}

.checkout-calendar-element > ul > li::before {
   content: '';

   position: absolute;
   top: 0;
   bottom: 0;
   left: -1.5rem;

   width: 0.5rem;
   height: 0.5rem;

   margin: auto 0;

   border-radius: 50%;

   background-color: var(--main-color);
}



/* Custom radios */

.checkout-radio {
   width: 100%;

   display: flex;
}

.checkout-radio > input {
   display: none;
}

.checkout-radio > label {
   width: 100%;

   display: flex;

   padding: 0.5rem 1rem;

   border-radius: 0.5rem;

   background-color: var(--soft-main-color);

   transition: background-color 0.1s linear, color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .checkout-radio > label {
      cursor: pointer;
   }
}

.checkout-radio > input:checked ~ label {
   background-color: var(--soft-secondary-color);

   color: #FFFFFF;
}



/* Diseños radios para las locaciones */

.checkout-place-img {
   width: 35%;

   aspect-ratio: 3 / 2;
   object-fit: contain;

   flex-shrink: 0;

   overflow: hidden;
   border-radius: 0.5rem;

   display: flex;
}

.checkout-place-img > img {
   width: 100%;
}

.checkout-place-data {
   width: 65%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 0.5rem;

   padding: 0.5rem;
}

.checkout-place-data > p {
   font-size: 1.5rem;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .checkout-place-data > p {
      font-size: 1.4rem;
   }
}

.checkout-place-data > span {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .checkout-place-data > span {
      font-size: 1.2rem;
   }
}

.checkout-place-group {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.checkout-place-group > h3 {
   font-size: 1.4rem;
   font-weight: 500;
   text-align: center;

   color: var(--dark-main-color);
}

.checkout-place-group > p {
   display: none;

   font-size: 1.4rem;
   font-weight: 300;
   text-align: center;

   color: var(--secondary-color);

   width: 100%;
}

.checkout-place-group.error > p {
   display: block;
}

.checkout-place-list {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .checkout-place-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      gap: 1rem;
   }
}



/* Diseños radios para los métodos de pago */

@media only screen and (min-width: 768px) {
   .checkout-payment-radio > label {
      background-color: #FFFFFF;

      border: 0.1rem solid var(--input-grey);
   }
}

.payment-label > img {
   width: 70%;

   margin: 0 auto;

   aspect-ratio: 10 / 3;
   object-fit: contain;
}

@media only screen and (min-width: 768px) {
   .payment-label > img {
      width: 100%;
   }
}

.checkout-payment-credit {
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 1rem;

   width: 100%;
}

.payment-label .checkout-payment-credit {
   column-gap: 3rem;
}

.checkout-payment-credit-text {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.checkout-payment-credit-text > p {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%
}

.checkout-payment-credit-text > span {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%
}

.checkout-payment-credit-imgs {
   width: 40%;

   display: flex;
   flex-wrap: wrap;
   gap: 0.5rem 1rem;
}

.checkout-payment-credit-img {
   width: calc((100% / 3) - (2rem / 3));

   display: flex;
   align-items: center;
}

.checkout-payment-credit-img > img {
   width: 100%;
}

.checkout-payment-webpay {
   display: flex;
   justify-content: center;
   align-items: center;

   width: 100%;
}

.checkout-payment-webpay-img {
   display: flex;
}

.payment-label .checkout-payment-webpay-img {
   width: 70%;
}

.checkout-payment-webpay-img > img {
   width: 100%;
}

.checkout-payment-transfer {
   display: flex;
   justify-content: center;
   align-items: center;

   width: 100%;
}

.checkout-payment-transfer > p {
   font-size: 1.8rem;
   font-weight: 500;
}

@media only screen and (min-width: 768px) {
   .checkout-payment-transfer > p {
      flex-grow: 0;

      font-size: 1.4rem;
      font-weight: 500;
   }
}

.checkout-payment-transfer > svg {
   width: 5rem;
   flex-shrink: 0;
}



/* Grupo de customs radio para los pagos */

.checkout-payment-radio-group {
   display: grid;
   grid-template-columns: 1fr;
   grid-auto-rows: 1fr;
   row-gap: 1.5rem;
}

@media only screen and (min-width: 768px) {
   .checkout-payment-radio-group {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 1.5rem;

      width: 60%;
      min-width: 80rem;
      
      margin: 0 auto;
   }
}

.checkout-payment-error {
   font-size: 1.4rem;
   font-weight: 300;
   text-align: center;

   margin-top: 1rem;

   color: var(--secondary-color);

   width: 100%;
}



/* Diseños para el componente de agenda */

.checkout-place-selected {
   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;
}

.checkout-place-selected-title {
   display: flex;
   align-items: center;
   column-gap: 1rem;
}

.checkout-place-selected-title > p {
   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);
}

.checkout-place-selected-title > div {
   flex-grow: 1;

   height: 0.1rem;

   background-color: var(--input-grey);
}

.checkout-place-selected-element {
   display: flex;
   align-items: center;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .checkout-place-selected-element {
      flex-direction: row;
      justify-content: space-between;
   }
}

.checkout-place-selected-element ~ .checkout-input-group {
   margin-top: 2rem;
}

.checkout-place-selected-place {
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 0.5rem;

   width: 100%;
}

@media only screen and (min-width: 768px) {
   .checkout-place-selected-place {
      width: unset;
      min-width: 50%;
   }
}

.checkout-place-selected-place > p {
   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;
}

.checkout-place-selected-place > span {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
}

.checkout-place-selected-additionals {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;

   margin-top: 1rem;
}

@media only screen and (min-width: 768px) {
   
}

.checkout-place-selected-additionals > p {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   display: flex;
   justify-content: space-between;
}

.checkout-place-selected-additionals > p > span {
   font-weight: 500;
}

.checkout-place-selected-button {
   padding: 0.75rem 2rem;
   margin-top: 2rem;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   border-radius: 1rem;

   font-size: 1.4rem;

   cursor: pointer;

   transition: background-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .checkout-place-selected-button {
      margin-top: 0;
   }
}

.checkout-place-selected-button:hover {
   background-color: var(--main-color);
}