.search-suggestions {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   margin-top: 2rem;

   overflow-y: auto;
}

@media only screen and (min-width: 768px) {
   .search-suggestions {
      z-index: var(--header-index);

      position: absolute;
      top: 100%;
      right: 0;
      left: 0;

      display: none;

      margin-top: 1rem;

      row-gap: 0;

      background-color: #FFFFFF;

      border-radius: 1.5rem;
   }

   .search-suggestions.active {
      box-shadow: 0 0.2rem 0.5rem 0.2rem rgba(0, 0, 0, 0.1);
      
      border: var(--input-border);
   }
}

.search-suggestion {
   display: flex;
   
   padding: 0.5rem;

   width: 100%;

   background-color: #FFFFFF;

   border-radius: 1rem;
}

@media only screen and (min-width: 768px) {
   .search-suggestion {
      padding: 0;

      background-color: transparent;

      border-radius: 0;

      cursor: pointer;

      transition: background-color 0.2s ease;
   }

   .search-suggestion:hover {
      background-color: var(--soft-main-color);
   }

   .search-suggestion:not(:last-child) {
      border-bottom: var(--input-border);
   }
}

.search-suggestion-img {
   flex-shrink: 0;

   display: flex;

   width: 30%;
}

@media only screen and (min-width: 768px) {
   .search-suggestion-img {
      width: 25%;
      max-width: 9rem;
   }
}

.search-suggestion-img > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
   mix-blend-mode: darken;
}

.search-suggestion-body {
   flex-grow: 1;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

@media only screen and (min-width: 768px) {
   .search-suggestion-body {
      padding: 1rem 1rem 1rem 0;
   }
}

.search-suggestion-name {
   width: 100%;

   font-weight: 500;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .search-suggestion-name {
      font-size: 1.4rem;
      font-weight: 400;

      transition: color 0.2s ease;
   }

   .search-suggestion:hover .search-suggestion-name {
      color: var(--main-color);
   }
}

.search-suggestion-data {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.search-suggestion-sku {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .search-suggestion-sku {
      font-size: 1.2rem;
   }
}

.search-suggestion-price {
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .search-suggestion-price {
      font-weight: 400;
      font-size: 1.4rem;
   }
}

.search-suggestions-nav {
   color: #FFFFFF;
   background-color: var(--main-color);

   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 3rem;

   padding: 0.7rem 0;

   border-radius: 2rem;

   transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .search-suggestions-nav {
      padding: 0.5rem 0;
   
      border-radius: 0;

      cursor: pointer;
   }

   .search-suggestions-nav:hover {
      background-color: var(--dark-main-color);
   }
}

.search-suggestions-nav:disabled {
   background-color: var(--light-main-color);
}

.search-suggestions-nav > svg {
   font-size: 2rem;
}

@media only screen and (min-width: 768px) {
   .search-suggestions-nav > svg {
      font-size: 1.6rem;
   }
}

.search-suggestions-nav > p {
   font-size: 1.8rem;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .search-suggestions-nav > p {
      font-size: 1.4rem;
   }
}



/* Funcionalidad */

input:focus ~ .search-suggestions {
   display: flex;
}