.warranties-grid {
   width: 100%;

}

@media only screen and (min-width: 992px) {
   .warranties-grid {
      max-width: 130rem;
   
      margin: 0 auto;
   
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas: "xxxx titl titl titl"
                           "side main main main"
                           "side main main main"
                           "yyyy pgtn pgtn pgtn";
      gap: 5rem;
   }
}

.warranties-grid-titl {
   grid-area: titl;
}

.warranties-grid-side {
   grid-area: side;
}

.warranties-grid-main {
   grid-area: main;
}

.warranties-grid-pgtn {
   grid-area: pgtn;
}

.warranties-title {
   margin: 4rem auto 2rem;

   color: var(--secondary-color);
   
   font-size: 2rem;
   font-weight: 500;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .warranties-title {
      color: var(--dark-main-color);

      font-size: 2.8rem;
      font-weight: 600;
   }
}

@import url('./warranties-nav.css');

@import url('./warranties-data.css');

@import url('./warranties-input.css');

@import url('./warranties-order.css');

@import url('./warranties-tracking.css');