.products-carousel {
   width: auto;

   margin-left: -0.5rem;
   margin-right: -0.5rem;
}

@media only screen and (min-width: 768px) {
   .products-carousel {
      margin-left: -2rem;
      margin-right: -2rem;
   }
}

.products-carousel-element {
   padding: 0 0.5rem;
}

@media only screen and (min-width: 768px) {
   .products-carousel-element {
      padding: 0 2rem;
   }
}

.products-carousel .slick-track {
   display: flex!important;
}

.products-carousel .slick-slide {
   height: inherit!important;
}

.products-carousel .slick-slide > div {
   height: 100%!important;

   padding: 1rem 0;
}