.product-attribute {
   display: flex;
   align-items: stretch;
   column-gap: 1rem;

   width: 100%;
}

.product-attribute-tag {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   background-color: var(--soft-grey);

   border-radius: 1rem;

   padding: 0.2rem 0.5rem;

   flex-grow: 1;
}

.product-attribute.highlight .product-attribute-tag {
   background-color: var(--light-main-color);
}

.product-attribute-tag > svg {
   width: 2rem;

   flex-shrink: 0;

   color: var(--light-main-color);
}

.product-attribute.highlight .product-attribute-tag > svg {
   color: #FFFFFF;
}

.product-attribute-tag > p {
   padding: 0 0.3rem;

   font-size: 1.2rem;
   line-height: 100%;
   text-align: center;

   flex-grow: 1;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .product-attribute-tag > p {
      font-size: 1.6rem;
   }
}

.product-attribute.highlight .product-attribute-tag > p {
   color: #FFFFFF;
}

.product-attribute-btn {
   width: 2.4rem;

   margin: auto 0;

   display: flex;

   flex-shrink: 0;

   color: var(--light-main-color);
}

@media only screen and (min-width: 768px) {
   .product-attribute-btn {
      cursor: pointer;

      transition: color 0.2s ease;
   }

   .product-attribute-btn:hover {
      color: var(--main-color);
   }
}

.product-attribute-btn > svg {
   width: 100%;
}

.product-attribute-tooltip {
   background-color: #FFFFFF;

   width: 80%;
   max-width: 60rem;

   padding: 1.5rem 2rem;

   border-radius: 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;
}

.product-attribute-tooltip-row {
   display: flex;
   justify-content: space-between;
   align-items: center;

   padding-bottom: 1.5rem;

   border-bottom: 0.01rem solid rgba(0, 0, 0, 0.15);
}

.product-attribute-tooltip-title {
   line-height: 100%;
}

.product-attribute-tooltip-title > span {
   font-weight: 500;

   color: var(--main-color);
}

.product-attribute-tooltip-close {
   display: flex;
   align-items: center;
   justify-content: center;
}

.product-attribute-tooltip-close > svg {
   font-size: 2rem;

   color: var(--secondary-color);
}

@media only screen and (min-width: 768px) {
   .product-attribute-tooltip-close > svg {
      font-size: 3rem;

      cursor: pointer;
   
      transition: color 0.2s ease;
   }

   .product-attribute-tooltip-close:hover > svg {
      color: var(--dark-secondary-color);
   }
}

.product-attribute-tooltip-body {
   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;
}

.product-attribute-tooltip-body > p {
   line-height: 100%;

   color: var(--light-grey);
}

.product-attribute-tooltip-body > p > span {
   font-weight: 500;

   color: var(--main-color);
}

.product-attribute-tooltip-body > p > span.regular {
   color: var(--black);
}

.product-attribute-tooltip-btns {
   display: flex;
   justify-content: center;

   padding-top: 1.5rem;
   
   border-top: 0.01rem solid rgba(0, 0, 0, 0.15);
}

.product-attribute-tooltip-btn {
   padding: 0.5rem 1rem;

   background-color: var(--main-color);
   color: #FFFFFF;

   border-radius: 0.5rem;

   line-height: 100%;

   cursor: pointer;

   transition: background-color 0.2s ease;
}

.product-attribute-tooltip-btn:hover {
   background-color: var(--dark-main-color);
}