.trust-info-container {
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
}

.trust-info-title {
   color: var(--dark-main-color);

   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .trust-info-title {
      font-size: 2.6rem;
   }
}

.trust-info {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-auto-rows: 1fr;
   gap: 2.5rem;
}

@media only screen and (min-width: 992px) {
   .trust-info {
      grid-template-columns: repeat(4, 1fr);
      gap: 10rem;
   }
}

.trust-element {
   width: 100%;
   max-width: 22rem;

   padding: 2rem;
   margin: 0 auto;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1rem;

   background-color: var(--dark-main-color);

   border-radius: 1rem;
}

.trust-element-icon {
   width: 6rem;

   font-size: 6rem;

   display: flex;

   color: #FFFFFF;
}

.trust-element-text {
   width: 100%;

   margin: auto 0;

   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: #FFFFFF;
}

.trust-element-button {
   width: 100%;

   padding: 0.5rem 1rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 0.5rem;

   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   border-radius: 0.5rem;

   cursor: pointer;
}

.trust-element-button > p {
   flex-grow: 1;

   text-align: center;
   line-height: 100%;
}

.trust-element-button > svg {
   font-size: 1.2rem;
   line-height: 100%;
}