.profile-grid {
   width: 100%;
   max-width: 130rem;

   margin: 0 auto;

   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-template-areas: "xxxx titl titl titl"
                        "side main main main"
                        "side main main main"
                        "yyyy pgtn pgtn pgtn";
   gap: 5rem;
}

.profile-grid-titl {
   grid-area: titl;
}

.profile-grid-side {
   grid-area: side;
}

.profile-grid-main {
   grid-area: main;
}

.profile-grid-pgtn {
   grid-area: pgtn;
}

.profile-title {
   margin: 4rem auto 2rem;

   color: var(--secondary-color);
   
   font-size: 2rem;
   font-weight: 500;
}

@media only screen and (min-width: 768px) {
   .profile-title {
      color: var(--dark-main-color);

      font-size: 2.8rem;
      font-weight: 600;
   }
}

@import url('./profile-nav.css');

@import url('./profile-data.css');

@import url('./password-modal.css');

@import url('./orders.css');

@import url('./order-log.css');