.profile-data {
   display: flex;
   flex-direction: column;
   row-gap: 2.5rem;

   width: 100%;
}

@media only screen and (min-width: 768px) {
   .profile-data {
      padding: 4rem 3rem;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5rem;

      background-color: #FFFFFF;

      border: var(--input-border);
      border-radius: 1rem;
   }
}

.profile-data-element {
   width: 100%;

   display: flex;

   padding: 0.7rem 1rem;

   border: 0.1rem solid var(--grey);
   border-radius: 2rem;

   position: relative;
}

@media only screen and (min-width: 768px) {
   .profile-data-element {
      padding: 1rem;

      border: var(--input-border);
      border-radius: 0.5rem;

      background-color: #FFFFFF;
   }
}

.profile-data-element > span {
   position: absolute;
   top: -0.8rem;
   left: 2rem;

   color: var(--dark-main-color);
   background-color: #FFFFFF;

   padding: 0 0.3rem;

   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;
   text-transform: capitalize;
}

@media only screen and (min-width: 768px) {
   .profile-data-element > span {
      top: -1.5rem;

      background-color: transparent;
      
      font-size: 1.4rem;
   }
}

.profile-data-element > p {
   width: 100%;

   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--main-color);
}

.profile-data-element-button {
   position: absolute;
   top: 0;
   right: 1rem;

   height: 100%;

   display: flex;
   align-items: center;

   font-size: 1.8rem;

   color: var(--input-grey);

   cursor: pointer;

   transition: color 0.2s linear;
}

.profile-data-element-button:hover {
   color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .profile-data-element > p {
      font-size: 1.6rem;
   }
}

.profile-password-button-container {
   display: flex;
   justify-content: center;

   margin-top: 4rem;
}

@media only screen and (min-width: 768px) {
   .profile-password-button-container {
      grid-column: 1 / span 2;

      margin-top: 3rem;
   }
}

.profile-password-button {
   color: #FFFFFF;
   background-color: var(--secondary-color);

   font-size: 1.4rem;
   font-weight: 500;
   line-height: 100%;

   padding: 0.7rem 2rem;

   border-radius: 5rem;
}

@media only screen and (min-width: 768px) {
   .profile-password-button {
      color: var(--dark-main-color);
      background-color: #FFFFFF;

      font-size: 1.6rem;

      padding: 1rem 2rem;

      border: 0.1rem solid var(--dark-main-color);

      cursor: pointer;

      transition: background-color 0.2s linear, color 0.2s linear;
   }

   .profile-password-button:hover {
      color: #FFFFFF;
      background-color: var(--dark-main-color);
   }
}