.product-accordion {
   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;

   max-width: 130rem;
   margin: 0 auto;
}

.product-accordion-header-overflow {
   overflow-y: hidden;
}

.product-accordion-header {
   display: flex;
   justify-content: space-between;

   overflow-y: auto;

   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
}

.product-accordion-header::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
}

@media only screen and (min-width: 992px) {
   .product-accordion-header {
      padding: 0 5rem;

      overflow-y: initial;
   }
}

.product-accordion-header-element {
   padding: 0.5rem 1.5rem;

   color: var(--grey);
   background-color: var(--input-grey);

   border-radius: 2rem;

   font-weight: 300;
   line-height: 100%;
   text-align: center;

   transition: color 0.1s linear, background-color 0.1s linear;
}

@media only screen and (min-width: 768px) {
   .product-accordion-header-element {
      min-width: 20rem;

      padding: 1rem 1.5rem;

      font-weight: 400;

      cursor: pointer;
   }
}

.product-accordion-header-element:not(:first-child) {
   margin-left: 0.5rem;
}

.product-accordion-header-element:not(:last-child) {
   margin-right: 0.5rem;
}

.product-accordion-header-element.active {
   color: #FFFFFF;
   background-color: var(--light-main-color);
}

@media only screen and (min-width: 768px) {
   .product-accordion-header-element.active {
      background-color: var(--main-color);
   }
}

.product-accordion-body {
   color: var(--grey);
   background-color: #FFFFFF;

   padding: 0.5rem 1.5rem;

   border: var(--input-border);
   border-radius: 1.5rem;

   font-weight: 300;

   text-align: justify;
   text-justify: inter-word;
}

@media only screen and (min-width: 768px) {
   .product-accordion-body {
      padding: 1.5rem 2rem;
   }
}

/* Comparison */

.product-accordion-comparison-overflow {
   overflow-y: hidden;
}

.product-accordion-comparison {
   display: flex;
   flex-direction: column;
   
   overflow-y: auto;
   
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
}

.product-accordion-comparison::-webkit-scrollbar {
   display: none;
}

.product-accordion-comparison > table {
   table-layout: fixed;
}

.product-accordion-comparison table > tbody {
   background-color: #FFFFFF;
}

.product-accordion-row {
   width: 100%;

   flex-grow: 1;
   flex-shrink: 0;

   display: grid;
   grid-template-columns: repeat(3, 1fr);
   
   border-top: var(--input-border);
   border-right: var(--input-border);
   border-left: var(--input-border);
}

.product-accordion-row:first-child {
   border-top-left-radius: 0.5rem;
   border-top-right-radius: 0.5rem;
}

.product-accordion-row:last-child {
   border-bottom: var(--input-border);
   border-bottom-left-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
}

.product-accordion-row > td {
   min-width: 30rem;

   transition: background-color 0.2s ease-in-out;
}

.product-accordion-row > td:first-child {
   background-color: var(--soft-main-color);
}

.product-accordion-row:hover > td {
   background-color: var(--soft-main-color);
}

.product-accordion-row-img {
   width: 100%;

   display: flex;
   align-items: center;
   justify-content: center;
   
   padding: 1rem 2rem;
}

.product-accordion-img {
   display: flex;

   width: 60%;

   aspect-ratio: 1 / 1;

   position: relative;
}

.product-accordion-img > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
   mix-blend-mode: darken;
}

.product-accordion-row-img:not(:last-child) {
   border-right: var(--input-border);
}

.product-accordion-row-brand {
   width: 100%;

   cursor: pointer;
}

.product-accordion-row-brand > a {
   display: flex;
   align-items: center;
   justify-content: center;
   
   padding: 1rem 2rem;
}

.product-accordion-row-brand img {
   width: 50%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}

.product-accordion-row-brand:not(:last-child) {
   border-right: var(--input-border);
}

.product-accordion-row-element {
   padding: 1rem 2rem;

   font-weight: 500;
   line-height: 100%;
   text-transform: uppercase;
   text-align: center;

   display: flex;
   align-items: center;
   justify-content: center;
}

.product-accordion-row-element > a {
   color: var(--main-color);

   cursor: pointer;

   transition: color 0.2s ease;
}

.product-accordion-row-element > a:hover {
   color: var(--dark-main-color);
}

.product-accordion-row-element:not(:last-child) {
   border-right: var(--input-border);
}

.product-accordion-row-attribute {
   padding: 1rem 2rem;

   display: flex;
   align-items: center;
   justify-content: space-between;
   column-gap: 1rem;
   
   transition: background-color 0.2s ease-in-out;
}

.product-accordion-row-attribute > p {
   font-weight: 500;
   line-height: 100%;
   text-transform: capitalize;
}

.product-accordion-row-attribute > span {
   line-height: 100%;
   text-align: end;
}

.product-accordion-row-attribute:not(:last-child) {
   border-right: var(--input-border);
}

/* details */

.product-accordion-details {
   display: flex;
   flex-direction: column;

   border: var(--input-border);
   border-radius: 0.5rem;

   background-color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .product-accordion-details {
      width: 50%;
      max-width: 60rem;

      margin: 0 auto;
   }
}

.product-accordion-detail {
   width: 100%;

   padding: 1rem 7.5%;

   flex-shrink: 0;
   
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.product-accordion-detail:not(:last-child) {
   border-bottom: var(--input-border);
}

.product-accordion-detail > p {
   font-weight: 500;
   line-height: 100%;
   text-transform: capitalize;
}

.product-accordion-detail > span {
   line-height: 100%;
}