.cart-toast-container:not(.removed) {
   /* background-color: var(--light-main-color)!important; */

   border: 0.1rem solid var(--soft-grey);
}

.cart-toast-title {
   font-size: 1.3rem!important;

   margin: 0.5rem 0!important;
   
   color: var(--dark-main-color)!important;
}

.removed .cart-toast-title {
   color: var(--light-secondary-color)!important;
}

.cart-toast-text {
   font-size: 1.4rem!important;
   text-transform: uppercase;

   margin: 0.5rem 0!important;
}

.cart-toast-container:not(.removed) .cart-toast-text {
   color: var(--black)!important;
}

.cart-toast-footer {
   font-size: 1.2rem!important;

   margin-top: 0.5rem!important;
   padding-top: 0.5rem!important;

   color: var(--main-color)!important;
   
   border-top: none!important;
}

.cart-toast-btns {
   display: flex!important;
   flex-direction: row!important;
   column-gap: 1rem;

   padding: 0!important;
}

.cart-toast-btn {
   flex: 1 1 0rem;

   margin-right: 0;
   margin-left: 0;

   padding: 1rem 0.5rem;

   font-size: 1.2rem;
   line-height: 100%;

   background-color: var(--grey);

   color: #FFFFFF;

   border-radius: 0.5rem;

   cursor: pointer;

   transition: filter 0.2s ease;
}

.cart-toast-btn:hover {
   filter: brightness(0.9);
}

.cart-toast-btn.link {
   background-color: var(--main-color);
}