.checkout-box {
   background-color: #FFFFFF;

   border: 0.1rem solid rgba(0, 0, 0, 0.1);
   border-radius: 2rem;

   padding: 2rem 1.5rem;

   display: flex;
   flex-direction: column;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .checkout-box {
      background-color: transparent;

      width: 60%;
      min-width: 80rem;

      padding: 0;
      margin: 0 auto;

      border: none;
      border-radius: 0;
   }
}

.checkout-box-title {
   display: flex;
   align-items: center;
   column-gap: 1rem;

   color: var(--secondary-color);

   margin-bottom: 2.5rem;
}

@media only screen and (min-width: 768px) {
   .checkout-box-title {
      display: none;
   }
}

.checkout-box-title > svg {
   font-size: 3rem;
}

.checkout-box-title > p {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;
}

.checkout-buttons {
   display: flex;
   justify-content: space-between;

   width: 100%;

   margin-top: 4rem;
}

.checkout-button {
   padding: 0.5rem 1rem;

   background-color: var(--main-color);

   color: #FFFFFF;

   border-radius: 1rem;

   letter-spacing: 0.05rem;
}

@media only screen and (min-width: 768px) {
   .checkout-button {
      padding: 1rem 2rem;

      border-radius: 0.5rem;

      transition: background-color 0.1s linear;
   }

   .checkout-button:not(:disabled):hover {
      background-color: var(--dark-main-color);

      cursor: pointer;
   }
}

.checkout-button:disabled {
   background-color: var(--light-main-color);
}

.checkout-button.next {
   margin-left: auto;
}

.checkout-button.submit {
   margin-left: auto;

   background-color: var(--green);
}

.checkout-button.submit:disabled {
   background-color: #53bd53;
}

.checkout-button.submit:not(:disabled):hover {
   background-color: #008300;

   cursor: pointer;
}