.page-404 {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 2rem 0;
}

@media only screen and (min-width: 768px) {
   .page-404 {
      flex-direction: row;
      justify-content: center;
      gap: 0 4rem;
   }
}

.page-404-img {
   width: 50%;

   display: flex;
}

@media only screen and (min-width: 768px) {
   .page-404-img {
      width: 30%;
      max-width: 40rem;
   }
}

.page-404-img > img {
   width: 100%;
}

.page-404-text {
   width: 80%;

   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1rem 0;
}

@media only screen and (min-width: 768px) {
   .page-404-text {
      width: 50%;
      max-width: 40rem;

      gap: 2rem 0;
   }
}

.page-404-text > p {
   font-size: 2.4rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;
}

@media only screen and (min-width: 768px) {
   .page-404-text > p {
      font-size: 2.8rem;
   }
}

.page-404-text > span {
   font-size: 1.8rem;
   line-height: 100%;
   text-align: center;

   color: var(--light-main-color);
}

@media only screen and (min-width: 768px) {
   .page-404-text > span {
      font-size: 2rem;
   }
}



/* Estilos específicos */

.section-404 {
   margin: var(--section-margin);

   width: var(--section-width);
   max-width: var(--section-max-width);
}

@media only screen and (min-width: 768px) {
   .section-404 {
      margin-top: 7rem;
   }
}