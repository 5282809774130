.newsletter-container {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   background-color: var(--light-secondary-color);

   color: #FFFFFF;

   border-radius: 1rem;

   padding: 1rem 2rem;

   box-shadow: var(--element-shadow);
}

@media only screen and (min-width: 768px) {
   .newsletter-container {
      align-items: center;
      row-gap: 5rem;
   }
}

.newsletter-title {
   width: 70%;

   margin: 0 auto;

   text-align: center;

   font-size: 2rem;
   font-weight: 400;
   line-height: 110%;
}

.newsletter-title > span {
   color: var(--dark-main-color);

   font-weight: 600;
   letter-spacing: 0.1rem;
}

.newsletter {
   width: 100%;

   display: flex;
   align-items: stretch;
}

@media only screen and (min-width: 768px) {
   .newsletter {
      width: 60%;
   }
}

.newsletter > input {
   width: 80%;

   border-top-left-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   
   background-color: rgba(255, 255, 255, 0.25);

   padding: 0.5rem 1rem;

   text-align: center;

   font-size: 1.6rem;
}

.newsletter > input::placeholder {
   color: #FFFFFF;
}

.newsletter > button {
   width: 20%;

   background-color: var(--dark-main-color);

   padding: 0.5rem 1rem;

   display: flex;
   align-items: center;
   justify-content: center;

   border-top-right-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
}

.newsletter-footer {
   font-size: 1.4rem;
   font-weight: 300;

   width: 100%;

   text-align: center;

   color: var(--input-grey);
}

@media only screen and (min-width: 768px) {
   .newsletter-footer {
      margin-top: -3rem;
   }
}