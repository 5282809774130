/* Input */

.warranties-tracking-form {
   display: flex;

   border: var(--input-border);
   border-radius: 1rem;

   overflow: hidden;
}

.warranties-tracking-form > input {
   flex-grow: 1;

   text-align: center;

   padding: 1rem 1.5rem;
}

.warranties-tracking-form > button {
   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   padding: 0 1rem;

   color: #FFFFFF;
   background-color: var(--main-color);
   
   cursor: pointer;

   transition: background-color 0.2s ease;
}

.warranties-tracking-form > button:disabled {
   background-color: var(--light-main-color);
}

.warranties-tracking-form > button:not(:disabled):hover {
   cursor: pointer;

   background-color: var(--dark-main-color);
}

.warranties-tracking-form > button > svg {
   font-size: 2rem;
}

.warranties-tracking-form > button > p {
   line-height: 100%;
}

.warranties-tracking-info {
   font-size: 2rem;
   font-weight: 300;
   text-align: center;

   color: var(--main-color);

   margin-top: 1rem;
}

.warranties-tracking-info.error {
   color: var(--secondary-color);
}



/* Data */

.warranties-info {
   grid-column: 1 / 3;

   display: flex;
   flex-direction: column;
   gap: 2rem;
}

@media only screen and (min-width: 768px) {
   .warranties-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
   }
}

.warranties-info:not(:last-child) {
   padding-bottom: 3rem;
   margin-bottom: 3rem;
   border-bottom: 1px solid var(--input-grey);
}

.warranties-info-text {
   display: flex;
   justify-content: space-between;
   row-gap: 0.5rem;
   column-gap: 1rem;
}

.warranties-info-text.column {
   flex-direction: column;
   align-items: center;
   justify-content: normal;
}

.warranties-info-text > span {
   font-size: 1.2rem;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .warranties-info-text > span {
      font-size: 1.4rem;
   }
}

.warranties-info-text > p {
   font-weight: 500;
   text-align: end;
}

.warranties-info-images {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 2rem;
}

.warranties-info-image {
   width: 100%;

   display: flex;

   aspect-ratio: 1 / 1;
}

.warranties-info-image > img {
   width: 100%;

   aspect-ratio: 1 / 1;

   object-fit: contain;
}